import '../styles/faq.scss';

import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import DownArrow from '../images/DownArrow.jpeg';
import UpArrow from '../images/UpArrow.jpeg';

function FaqPage() {

  const onClick_section = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> onClick_section ' + event.target.name );
    if ( !showSections[index] ) {
      setShowSections( { ...showSections, [index]: true } );
    }
    else {
      setShowSections( { ...showSections, [index]: false } );
    }
  };

  const onClick = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> onClick ' + event.target.name, showResults[index] );
    if ( !showResults[index] ) {
      setShowResults( { ...showResults, [index]: true } );
    }
    else {
      setShowResults( { ...showResults, [index]: false } );
    }
  };

  const [ showSections, setShowSections ] = React.useState( { 1: true } );
  const [ showResults, setShowResults ] = React.useState( {} );

  return (
    <Layout header={'menu'}>
      <SEO
        title="Get answers related to Life &amp; Home Insurance Queries "
        description="Our FAQ section will help you get answers to the most frequently asked questions, related to Life and Home Insurance. Visit now!"
        canonicalUrl='https://www.getmybubble.io/faq'
        robots={ { 1:'index', 2: 'follow' } }
      />

      <div id="faq" className="wrapper">
        <div className="flexColumn carrierCommonPage">
          <div className="company-info-container container-fluid">
            <div className="row">
              <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                  <h1 className="carrier-plan-heading section70" style={{ lineHeight: '1.55', textAlign: 'center' }}>
                                    Frequently Asked Questions
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="container faq-margin-bottom common-container-space">
            <div className="row">
              <div className="col-lg-12">
                {/*About Home Insurance*/}

                <div className="row">
                  <div className="col-lg-12">
                    <div className="faqFlex bg-white-border">
                      <div className="faqLeftContainer faq-left-container">
                        <div className="col-sm-12" onClick={( e ) => onClick_section( e, 1 )}>
                          <div className="align-faq-que position-relative">
                            <div className="faq-section-headings">
                                                                About Life Insurance
                            </div>

                            <img
                              src={showSections[1] ? UpArrow : DownArrow}
                              className="faq-dd m-0" name="1" alt="Bubble Life Insurance"/></div>
                        </div>

                        <div className="col-12 both-space-padding">
                          <div className="row">
                            {showSections[1] && <>
                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 3 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> As a stay-at-home mom, do I
                                                                                            need Life
                                                                                            Insurance? </div><img src={showResults[3] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0" name="0"
                                            alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[3] && `"Regardless of whether you
                                                                                        bring
                                                                                        home any income or not, it's best for all adults in the household to be
                                                                                        insured.
                                                                                        If something were to happen to you, a stay-at-home mom, day-to-day
                                                                                        functions
                                                                                        like child care, house cleaning, cooking, and the likes can get very
                                                                                        expensive
                                                                                        for your partner to bear.
                                                                                        "`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 4 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> What is term life insurance?
                                          </div><img src={showResults[4] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0" name="4"
                                            alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[4] && `Term life insurance ends
                                                                                        after a
                                                                                        certain period of time and you get to choose how long the term will
                                                                                        last. It can
                                                                                        last from 10 to 30 years even. You can consider various factors to see
                                                                                        if term
                                                                                        life is a good fit for you ÔÇô income, mortgage payments, monthly
                                                                                        expenses, and
                                                                                        the likes.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 5 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> Can I cancel my life
                                                                                            insurance
                                                                                            policy? </div><img src={showResults[5] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0" name="6"
                                            alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[5] && `Sure, you can. Much like
                                                                                        most
                                                                                        other contracts, a life insurance policy also expires. You can then
                                                                                        chose to
                                                                                        reapply or not keeping in mind your financial goals and commitments.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 6 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> What's the best age to get
                                                                                            life
                                                                                            insurance? </div><img src={showResults[6] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0" name="16"
                                            alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[6] && `The right age would depend
                                                                                        on your
                                                                                        financial position and commitments. However, the best age would be as
                                                                                        young as
                                                                                        you possibly can. Buying life insurance at a young age means you may not
                                                                                        need a
                                                                                        medical exam, you can lock in your health rating, and it's one financial
                                                                                        goal
                                                                                        you can check off your list quite early.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 7 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> What is group life insurance
                                                                                            and is
                                                                                            it enough for me? </div><img src={showResults[7] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0"
                                            name="22" alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[7] && `Group life insurance is
                                                                                        typically
                                                                                        offered by companies and organizations to their employees as an integral
                                                                                        part of
                                                                                        benefits. You will be able to avail the policy only as long as you are
                                                                                        employed
                                                                                        with the company. It can be free but has relatively low coverage. In
                                                                                        such cases,
                                                                                        it's best to have an individual policy so you have insurance for a
                                                                                        longer term,
                                                                                        for a greater amount, and not dependent on job changes.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 8 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> What happens if I outlive my
                                                                                            life
                                                                                            insurance? </div><img src={showResults[8] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0" name="25"
                                            alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[8] && `If your term policy
                                                                                        expires and
                                                                                        you still have loved ones depending on you, it's best to renew your
                                                                                        policy. At
                                                                                        this point you could add more coverage or alter your policy to fit your
                                                                                        needs.
                                                                                    `}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 9 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> Under what circumstances can
                                                                                            I ignore
                                                                                            life insurance? </div><img src={showResults[9] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0"
                                            name="26" alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[9] && `There are no circumstances
                                                                                        where
                                                                                        you can ignore buying life insurance. Even if you think you're too young
                                                                                        or have
                                                                                        a lot saved, you still need to protect your family's financial future
                                                                                        because
                                                                                        anything can happen. In fact, the earlier you get life insurance you
                                                                                        lock in a
                                                                                        low premium and a good health rating for decades.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 10 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> Can I buy multiple term
                                                                                            policies?
                                          </div><img src={showResults[10] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0" name="38"
                                            alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[10] && `You could buy more than
                                                                                        one term
                                                                                        policy depending on when and why you need them. But you have to keep in
                                                                                        mind
                                                                                        that multiple policies means multiple premiums, which means the premiums
                                                                                        could
                                                                                        also vary significantly considering your health and age.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 11 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> Do empty nesters need life
                                                                                            insurance?
                                          </div><img src={showResults[11] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0" name="39"
                                            alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[11] && `Yes. Especially, if you
                                                                                        have
                                                                                        dependants, children going to college, and if you have exhausted your
                                                                                        savings.
                                                                                    `}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 12 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> Why do I need life insurance
                                                                                            when I'm
                                                                                            healthy? </div><img src={showResults[12] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0" name="40"
                                            alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[12] && `Life insurance is to
                                                                                        protect your
                                                                                        loved ones. With the pandemic and other natural disasters, you never
                                                                                        know what's
                                                                                        in store for all of us. With life insurance you can be assured that your
                                                                                        loved
                                                                                        ones are taken care of.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*End of Home Insurance */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default FaqPage;
